import React from "react";

  const BtnAction = ({action, onClick})=>{
    
    return(
      <>
          <span className={` ${action === 'prev' ? 'prevBtn flex-row-reverse': 'nextBtn'}`} 
            onClick={onClick}
           >
            <strong className="icon-mov pe-2">
              {action === 'prev'? ' < ' : ' > '}
            </strong>
          </span>
      </>
    )
  }

export default BtnAction;
